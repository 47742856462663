import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import '../reset.css'

import {
	wrapper,
	heading,
	heroContainer,
	heroContent,
	heroImage,
	sectionContainer,
	contactButton,
	contactLink,
	contactContainer,
	column,
} from './index.module.css'

const IndexPage = () => {
	return (
		<div className={wrapper}>
			<div className={heroContainer}>
				<StaticImage
					className={heroImage}
					layout='fullWidth'
					alt=''
					src='../images/curtain.jpg'
					formats={['auto', 'webp', 'avif']}
				/>
				<div className={heroContent}>
					<h1 className={heading}>Your stage, your rules.</h1>
					<p>Solutions for fine grain hoist control and automation with position feedback and cue choreography.</p>
					<button className={contactButton}>
						<Link className={contactLink} to="#contact">Get Started</Link>
					</button>
				</div>
			</div>
			<div className={sectionContainer} id='contact'>
				<h2>Contact DCT today for a quote and further information.</h2>
				<div className={contactContainer}>
					<p>Direct Control Technologies Inc.<br/>2700 Tabernacle Church Rd.<br/>Pleasant Garden, NC 27313</p>
					<div className={column}>
						<a href="mailto: sales@directcontroltech.com">sales@directcontroltech.com</a>
						<a href="tel:+13364858460">(336) 485-8460</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default IndexPage
